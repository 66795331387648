import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import { styled } from '@mui/system';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import FaceIcon from '@mui/icons-material/Face';

import BnWSection from '../components/BnWSection';
import BnWContent from '../components/BnWContent';
import { Typography } from '@mui/material';

const Section = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	// backgroundColor: '#ffffff',
	zIndex: 1,
});

// eslint-disable-next-line
export const BlogPostTemplate = ({
	content,
	contentComponent,
	description,
	tags,
	title,
	helmet,
}) => {
	const PostContent = contentComponent || Content;

	return (
		<BnWSection
			className='section'
			sx={{ boxShadow: 12 }}
			mx={8}
			mt={6}
			borderRadius={2}
			elevation={3}
		>
			{helmet || ''}
			<BnWContent px={2}>
				<Grid container spacing={2} sx={{ justifyContent: 'space-between' }} py={4}>
					<Grid item xs={12} sm={12} md={12} className='section'>
						<Typography
							variant='h1'
							className='title is-size-2 has-text-weight-bold is-bold-light'
						>
							{title}
						</Typography>
						{/* <Typography dangerouslySetInnerHTML={{ __html: description.html }} /> */}
						<PostContent content={content} />
						{/* {tags && tags.length ? (
							<>
								<Typography variant='h4'>Tags</Typography>
								<Stack className='taglist' direction='row'>
									{tags.map((tag) => (
										<Chip
											key={tag + `tag`}
											icon={<FaceIcon />}
											label={tag}
											variant='outlined'
											component='a'
											href={`/tags/${kebabCase(tag)}/`}
											clickable
										/>
									))}
								</Stack>
							</>
						) : null} */}
					</Grid>
				</Grid>
			</BnWContent>
		</BnWSection>
	);
};

BlogPostTemplate.propTypes = {
	content: PropTypes.node.isRequired,
	contentComponent: PropTypes.func,
	description: PropTypes.string,
	title: PropTypes.string,
	helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
	const { markdownRemark: post } = data;

	return (
		<Layout>
			<BlogPostTemplate
				content={post.html}
				contentComponent={HTMLContent}
				// description={post.frontmatter.description}
				helmet={
					<Helmet titleTemplate='%s | Blog'>
						<title>{`${post.frontmatter.title}`}</title>
						<meta
							name='description'
							// content={`${post.frontmatter.description.html}`}
						/>
					</Helmet>
				}
				tags={post.frontmatter.tags}
				title={post.frontmatter.title}
			/>
		</Layout>
	);
};

BlogPost.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.object,
	}),
};

export default BlogPost;

export const pageQuery = graphql`
	query BlogPostByID($id: String!) {
		markdownRemark(id: { eq: $id }) {
			id
			html
			frontmatter {
				date(formatString: "MMMM DD, YYYY")
				title
				description {
					html
				}
				tags
			}
		}
	}
`;
